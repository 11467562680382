import React from 'react';
import { Box, styled } from '@mui/material';
import GifImage from "../Assets/icons/gif/ecozones.gif"

const CenteredImage = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
});

const Mainloder = () => {
    return (
        <>
            <CenteredImage>
                <img
                    src={GifImage}
                    alt="Centered"
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
            </CenteredImage>
        </>
    );
};

export default Mainloder;
