import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
//import Loading Page 
import Loading from "../Theme/Main.jsx";
const HomePage = lazy(() => import('../Components/Home/Main.jsx'));
const Main = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route>
          <Route path='/' element={<HomePage />} />
        </Route>
      </Routes>
    </Suspense>

  )
}

export default Main