import React, { lazy, Suspense } from 'react';
import Layout from "./Layout/Main";
import Routes from "./Routes/Main"
//import Loading Page 
import Loading from "./Theme/Main";

const Header = lazy(() => import('./Components/Header/Main.jsx'));
const Footer = lazy(() => import('./Components/Footer/Main.jsx'));

const App = () => {
  return (
    <>
      <Suspense fallback={<Loading />}>
        <Header />
        <Routes />
        <Layout />
      </Suspense>
      <Footer />
    </>
  );
};

export default App;
